const env = {
    // url: 'http://localhost:8080/simplifica-gabinete/api/',
    // api: 'http://localhost:8080/simplifica-gabinete/api/api/v1/',
    // baseUrl: 'http://localhost:8081/',
    baseUrl: 'https://sistema.simplificagabinete.com.br/',
    url: 'https://api.simplificagabinete.com.br/',
    api: 'https://api.simplificagabinete.com.br/api/v1/',
    title: 'Simplifica Gabinete',
    google_maps_key: 'AIzaSyBewHEW-s8dW0CRMD733r2Hs42RB5E0vPM',
}

export default env;
